import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Form.css'
import axios from 'axios'
import { Container, Input, Form, FormGroup, Label, Button,Row,Col } from "reactstrap";
import { getQR } from './GenerateQR';
function Formm() {
    const [name,setName] = useState(null);
    const [mobile,setMobile] = useState(null);
    const [loading,setLoading] = useState(false);
    const [uri,setUri] =useState(null);
    const [error,setError] = useState('');
 
    const handleRequestAccess = async () => {
        if(name==null && mobile == null){
           setError('Please fill credentials correctly.')
        }
        else{
        setLoading(true);
        await axios
          .post(`https://htj5xy3nrv.us-east-1.awsapprunner.com/api/nCount/odk/odisha-project/create-app-user`, {
            projectId: 1,
            formId: "Agri%20Inventory",
            roleId: 2,
            displayName:`${name}-${mobile}-Agricultural Inventory`
          })
          .then(function (response) {
            setLoading(false);
            setUri(getQR(response.data.token))
          })
          .catch(function (error) {
            setLoading(false);
            setLoading(false);
            console.log(error);
          });
        }
      };

  return (
    <Container>
        <Row style={{justifyContent:'center',alignItems:'center'}}>
            <Col md="6" sm="12" xs="12">
                <center>
                <h4 style={{marginTop:40}}>Agricultural Inventory</h4>

                </center>
            <Form style={{border:'1px solid',padding:40,borderRadius:10,marginTop:20}}>
    <FormGroup>
                <Input placeholder='Your Mobile Number' onChange={(e)=>setMobile(e.target.value)}  style={{ marginTop: 20 }}  className="inputBox1"/>
                <Input placeholder='Your Name'  onChange={(e)=>setName(e.target.value)}     style={{ marginTop: 20 }} className="inputBox1"/>
               <center>

                <Button size='sm' disabled={uri?true:false}  style={{ marginTop: 20,background:'#125912' }} onClick={handleRequestAccess}>{loading?'Generating...':'Generate QRCode'}</Button>
                {error!='' &&  error}
               </center>
                </FormGroup>
                </Form>
            </Col>
        </Row>
        {uri &&
        <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col md="6" sm="12" xs="12">
            <center>
            <p>Please scan it on ODK Collect.</p>
             <img style={{ width: 200, height: 200 }} src={uri} alt="QR Code"/>
             <p style={{ marginTop: 10,display:'flex',justifyContent:'center' }}>
              <a href={uri} download="Boundary-Mapping-QRCode.jpg">
                <Button style={{ backgroundColor: "#124912",fontSize:14,border:'none'}} size="sm" className="submitButton">
                  Download QR
                </Button>
              </a>
            <a href="https://play.google.com/store/apps/details?id=org.odk.collect.android&hl=en&gl=US" target="_blank">
                <Button size="sm" style={{marginLeft:5,fontSize:14,background:'#aa8d27',border:'none'}} className="submitButton">
                  Get ODK Collect
                </Button>
              </a>
              
            </p>
            </center>
            </Col>
        </Row>
}

    </Container>
  )
}

export default Formm